@import url(https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap);
*{
    color:white;
    font-family: 'MedievalSharp', cursive;
    font-size: 2vh;

}

#mobile {
    display: none;
  }

img{
    max-width: 101px;
    min-width: 100px;
    border: 4px solid black;

}

.monster-box{
    display: flex;
    min-height: 50%;
    width: 95%;
    border: 5px solid black;
    border-color: black;
    align-items: flex-start;
    justify-content: center;
    margin-top: 2vh;
    background-color: rgba(211, 211, 211, 0.598);
}

.portrait-image{
    margin: 5px;
    margin: 5px;
}


.portrait-flavor{
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 95%;    
}


.focus-button{
    margin: 10px;
}

.button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.monster-name{
    color: black;
    font-size: x-large;
    text-align: center;
    border-style: solid;
    border-width: 5px;
    border-left: green;
    border-right: green;

}

.chance-text{
    text-align: left;
    font-size:smaller;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    text-align: center;
    padding-left: 0px;
    margin-top: .25%;
    position: absolute;
    z-index: 9;
}

.deathScreen{
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align:center;
    width: 45.3%;
    height: 50%;
    background-color: rgba(128, 128, 128, 0.851);
    z-index: 9;
    position: absolute;
    top:15%;
    left:27.25%;
}

.deathText{
    font-size: 300%;
    margin:0;
    padding:0;
}

.deathHint{
    font-size: 150%;
    padding-left: 10%;
    padding-right: 10%;
}

.deathButtons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    grid-gap:5%;
    gap:5%;
    width: 100%
}

.deathButton{
    min-width: 15%;
    max-width: 30%;
    font-size: 110%;
}

.deathButton:hover{
    cursor: pointer;
    color:red;
}

.creditsButton{
    cursor: pointer;
}

.creditsButton:hover{
    color:red;
}

.cryButton{
    cursor:pointer
}

.cryButton:hover{
    color:red
}

.equippedItemsSection{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width:80%;
    margin-bottom: 5%;
    /* background-color: green; */

}

.inspectSection{
    display:flex;
    width:80%;
    justify-content: space-around;
    justify-self: center; 
    align-items: center;
    /* background-color: green; */
    margin-top: -10%;
}

.winScreen{
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align:center;
    width: 45.3%;
    height: 60%;
    background-color: rgba(128, 128, 128, 0.851);
    z-index: 9;
    position: absolute;
    top:10%;
    left:27.25%;
}

.winText{
    padding-left: 10%;
    padding-right: 10%;
    font-size: 125%;
}

.winEnd{
    font-size: 275%;
}

html,body{
    margin:0px
}

@media only screen and (max-width: 600px) {
    #root {
      display: none;
    }
  
    html,
    body {
      height: 100%;
    }
  
    body {
      background-color: #d6cca9;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    #mobile {
      display: flex;
      flex-direction: column;
      height: 70%;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      font-size: 200%;
    }

  
    #mobile > p {
      color: black;
      font-size: x-large;
      text-align: center;
      padding: 40px;
    }
  }
*{
    font-family: cursive;
}

.landingPage{
    min-height: 100vh;
    min-width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(https://i.pinimg.com/originals/06/c3/95/06c3954b72ae8cfe586ec151efeb29cc.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: darkgray;
    color:green;
    border-style: none;
    /* -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite; */
     -webkit-animation: flickerAnimation 1s infinite;
             animation: flickerAnimation 1s infinite;

}

.landingHeader1{
    display:flex;
    width: 25vw;
    justify-content: center;
    grid-gap: 1.5vw;
    gap: 1.5vw;
    flex-direction: row;
    text-align: center;
    padding-top: 10%;
    font-family: 'MedievalSharp', cursive;
    font-size: 5vh;
}

.landingHeader11{
    font-size: 5vh;

}

.landingHeader2{
    display: flex;
    flex-direction: column;
    max-width: 42.5vw;
    text-align: center;
    font-family: 'MedievalSharp', cursive;
    margin-top: 7.5vh;
    font-size: 7.5vw;}

.startButton{
    display:flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    min-width:20%;
    height:10vh;
    font-family: 'MedievalSharp', cursive;
    font-size: 8vh;
}

.startButton:hover{
    color:red
}


.startingPage{
    /* height:100%; */
    width:100%;
    margin-top: -10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.startingScript{
    display:flex;
    flex-direction: column;
    text-align: center;
    margin-top: 0vh;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    padding-left: 2.5vh;
    padding-right: 2.5vh;
    width:50vw;
    min-height:-webkit-fit-content;
    min-height:-moz-fit-content;
    min-height:fit-content;
    background-color: rgba(0, 0, 0, 0.666);
    font-family: 'MedievalSharp', cursive;
    font-size: 3.5vh;

    /* font-family: Trattatello;
    font-size: 3vh; */
}

.introScript{
    font-size: 3vh;
    
}

.beginButton{
    align-self: center;
    font-size: 3.5vh;
    font-family: 'MedievalSharp', cursive;}

.beginButton:hover{
    cursor: pointer;
    color:red;
}



@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:.92; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:.92; }
    100% { opacity:1; }
  }


*{
    font-family: 'MedievalSharp', cursive;
}

.controlsPage{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    justify-self: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.666);
    width: 40vw;
    height: 75vh;
    margin-top: -10vh;
    
}

.controls{
    min-width: 20%;
    /* background-color: pink; */
    margin-bottom: -5%;
    min-height:7.5%;
    align-self: center;
    text-align: center;
    cursor:pointer;
    font-size: 175%;
}

.divider{
    width: 95%;
    height:5px;
    /* background-color: rgba(0, 0, 0, 0.666); */
    background-color: white;
}

.playerPic{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    width:95%;
    height: 25%;
}

.instructions{
    display:flex;
    flex-direction: column;
    height: 20%;
    width: 95%;
    align-items: center;
    justify-content: center;

}

.controlText{
    font-size: 2.5vh;
    /* margin-top: -.25vh; */
    margin-bottom: 2vh;
    /* background-color: green; */
}

.allArrows{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    height:40%;
    width:75%;
    margin-bottom: 1.5vh;
    /* margin-top: 3%; */
    /* background-color: pink; */
}

.controlsOr{
    height:50%;
    align-self: flex-start;
    /* background-color: yellow; */
    margin-top: 5%;
    font-size: 90%;
    margin-left: -.45vw;
}

.arrows{
    height: 50%;
    width: 35%;
    display:flex;
    flex-direction: column;
    /* background-color: lightgreen; */
    /* margin-top: 3vw; */
    grid-gap:2px;
    gap:2px;

}


.bottomArrows{
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height:100%;
    min-width: 33%;
    text-align: center;
    /* background-color: blue; */
    grid-gap: 2px;
    gap: 2px;

}

.upArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width:33%;
    min-height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
    margin-bottom: 2px;
}

.leftArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}

.downArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}

.rightArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}



.wasd{
    height: 50%;
    width: 35%;
    display:flex;
    flex-direction: column;
    /* background-color: lightgreen; */
    /* margin-top: 3vw; */
    grid-gap:2px;
    gap:2px;
}

.wasdW{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 115%;
    font-weight: bolder;
    margin-bottom: 2px;
}

.wasdA{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}

.wasdS{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}

.wasdD{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}

.controlsMisc{
    width: 95%;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    /* border-style: solid;
    border-color: black;
    background-color: rgba(211, 211, 211, 0.214); */
    line-height: 2.5vw;
    font-size: 2.25vh;
    grid-gap:5%;
    gap:5%
}

.controlsButton{
    
    min-width: 20%;
    /* background-color: pink; */
    min-height:7.5%;
    align-self: center;
    text-align: center;
    cursor:pointer;
    font-size: 175%;
    font-family: 'MedievalSharp', cursive;
}

.controlsButton:hover{
    color:red
}
