@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');

*{
    font-family: 'MedievalSharp', cursive;
}

.controlsPage{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    justify-self: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.666);
    width: 40vw;
    height: 75vh;
    margin-top: -10vh;
    
}

.controls{
    min-width: 20%;
    /* background-color: pink; */
    margin-bottom: -5%;
    min-height:7.5%;
    align-self: center;
    text-align: center;
    cursor:pointer;
    font-size: 175%;
}

.divider{
    width: 95%;
    height:5px;
    /* background-color: rgba(0, 0, 0, 0.666); */
    background-color: white;
}

.playerPic{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    width:95%;
    height: 25%;
}

.instructions{
    display:flex;
    flex-direction: column;
    height: 20%;
    width: 95%;
    align-items: center;
    justify-content: center;

}

.controlText{
    font-size: 2.5vh;
    /* margin-top: -.25vh; */
    margin-bottom: 2vh;
    /* background-color: green; */
}

.allArrows{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    height:40%;
    width:75%;
    margin-bottom: 1.5vh;
    /* margin-top: 3%; */
    /* background-color: pink; */
}

.controlsOr{
    height:50%;
    align-self: flex-start;
    /* background-color: yellow; */
    margin-top: 5%;
    font-size: 90%;
    margin-left: -.45vw;
}

.arrows{
    height: 50%;
    width: 35%;
    display:flex;
    flex-direction: column;
    /* background-color: lightgreen; */
    /* margin-top: 3vw; */
    gap:2px;

}


.bottomArrows{
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height:100%;
    min-width: 33%;
    text-align: center;
    /* background-color: blue; */
    gap: 2px;

}

.upArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width:33%;
    min-height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
    margin-bottom: 2px;
}

.leftArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}

.downArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}

.rightArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}



.wasd{
    height: 50%;
    width: 35%;
    display:flex;
    flex-direction: column;
    /* background-color: lightgreen; */
    /* margin-top: 3vw; */
    gap:2px;
}

.wasdW{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 115%;
    font-weight: bolder;
    margin-bottom: 2px;
}

.wasdA{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}

.wasdS{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}

.wasdD{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:33%;
    height:100%;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.338);
    font-size: 125%;
    font-weight: bolder;
}

.controlsMisc{
    width: 95%;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    /* border-style: solid;
    border-color: black;
    background-color: rgba(211, 211, 211, 0.214); */
    line-height: 2.5vw;
    font-size: 2.25vh;
    gap:5%
}

.controlsButton{
    
    min-width: 20%;
    /* background-color: pink; */
    min-height:7.5%;
    align-self: center;
    text-align: center;
    cursor:pointer;
    font-size: 175%;
    font-family: 'MedievalSharp', cursive;
}

.controlsButton:hover{
    color:red
}